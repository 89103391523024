<template>
  <CaseCard
    color="orange lighten-3"
    icon="mdi-headset"
    :item="item"
    :statuses="metadata.statuses"
    :to="to"
    :types="metadata.categories"
    :departments="metadata.departments"
    :subject-areas="metadata.subjectAreas"
    :active-types="[item.categoryId]"
    :active-departments="[item.departmentId]"
    :active-subject-areas="item.subjectAreas.map(s => s.value)"
    :hide-status="hideStatus"
  >
    <template #body>
      <v-list
        class="py-0"
        dense
        disabled
      >
        <v-subheader class="mb-n2">
          {{ $t('supportTickets.card.assignedTo') }}
        </v-subheader>
        <v-list-item-group color="primary">
          <v-list-item
            class="font-weight-bold"
            dense
          >
            <v-list-item-icon>
              <v-icon>mdi-account-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ item.assignedToFullNameWithDetails }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-subheader class="my-n1">
          {{ $t('supportTickets.card.newestEntries') }}
        </v-subheader>
        <v-list-item-group>
          <v-list-item
            v-for="(entry, i) in item.newestEntrySummaries"
            :key="i"
            dense
          >
            <v-list-item-icon>
              <v-icon> {{ entry.icon }} </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ entry.title }}
                {{ entry.entryDateTime | formatDateTime }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
  </CaseCard>
</template>

<script>
import CaseCard from '@/components/CaseCard'

export default {
  name: 'SupportTicketCard',
  components: {
    CaseCard
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    },
    to: String,
    hideStatus: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>
