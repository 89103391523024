<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
    scrollable
  >
    <template #activator="{ on, attrs }">
      <span v-if="listButton">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="d-none d-sm-block"
          text
          @click="init"
        >
          Ny supportsag
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </span>
      <span v-else>
        <v-btn
          small
          plain
          v-bind="attrs"
          v-on="on"
          @click="init"
        >
          <v-icon left>mdi-plus</v-icon>
          Ny supportsag
        </v-btn>
      </span>
    </template>

    <v-card>
      <v-card-title class="title black--text accent">
        {{ !!createResult ? 'Sagen er nu oprettet' : 'Her kan du oprette en supportsag' }}
      </v-card-title>

      <Loading :loading="!loaded" />

      <v-card-text v-if="loaded && model">
        <v-container
          v-if="!createResult"
          fluid
        >
          <v-row>
            <v-col>
              <v-text-field
                v-model="model.data.externalCode"
                label="Ekstern reference"
              />

              <div class="mb-1 font-italic">
                Alle nedenstående felter skal udfyldes
              </div>

              <v-autocomplete
                v-model="model.data.calledBy"
                :items="model.metadata.calledBys"
                item-text="text"
                item-value="value"
                label="Rekvirent"
                return-object
                :search-input.sync="calledByInput"
                auto-select-first
                small-chips
                outlined
dense @input="calledByInput = null"
              />
              <v-autocomplete
                v-model="model.data.department"
                :items="model.metadata.departments"
                item-text="text"
                item-value="value"
                label="Enhed"
                return-object
                :search-input.sync="departmentInput"
                auto-select-first
                small-chips
                outlined
dense @input="departmentInput = null"
              />
              <v-autocomplete
                v-model="model.data.location"
                :items="model.metadata.locations"
                item-text="text"
                item-value="value"
                label="Lokation"
                return-object
                :search-input.sync="locationInput"
                auto-select-first
                small-chips
                outlined
dense @input="locationInput = null"
              />
              <v-autocomplete
                v-model="model.data.category"
                :items="model.metadata.categories"
                item-text="text"
                item-value="value"
                label="Kategori"
                return-object
                :search-input.sync="categoryInput"
                auto-select-first
                small-chips
                outlined
dense @input="categoryInput = null"
              />
              <v-autocomplete
                v-model="model.data.subjectAreas"
                :items="model.metadata.subjectAreas"
                item-text="text"
                item-value="value"
                label="Tekniske områder"
                return-object
                :search-input.sync="subjectInput"
                auto-select-first
                small-chips
                outlined
dense multiple @input="subjectInput = null"
              />
              
              <v-text-field
                v-model="model.data.title"
                outlined
                label="Titel"
              />
              <v-textarea
                v-model="model.data.text"
                outlined
                auto-grow
                rows="3"
                label="Beskrivelse"
              />
              <v-textarea
                v-model="model.data.contactDetails"
                outlined
                auto-grow
                rows="3"
                label="Person der skal kontaktes"
              />
            </v-col>
          </v-row>
        </v-container>
        <div
          v-if="!!createResult"
          class="pt-4"
        >
          <div>Din sag blev oprettet med følgende nummer:</div>
          <div>
            <router-link :to="`/supportticket/${createResult.model.id}`">
              {{ createResult.model.code }}
            </router-link>
          </div>
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!createResult"
          outlined
          color="primary"
          @click="cancel"
        >
          Annuller
        </v-btn>
        <v-btn
          v-if="!createResult"
          color="primary"
          :loading="saving"
          :disabled="!isValid"
          @click="create"
        >
          Opret supportsag
        </v-btn>
        <v-btn
          v-if="!!createResult"
          outlined
          color="primary"
          @click="close"
        >
          Luk
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import Loading from '@/components/Loading'

  export default {
    name: 'SupportTicketCreate',
    components: {
      Loading
    },
    props: {
      listButton: {
        default: true,
        type: Boolean,
      },
    },
    data () {
      return {
        model: Object,
        createResult: null,
        //view only
        dialog: false,
        loaded: false,
        saving: false,
        isValid: false,
        calledByInput: null,
        departmentInput: null,
        locationInput: null,
        categoryInput: null,
        subjectInput: null
      }
    },
    watch: {
      model: {
        deep: true,
        handler() {
          if (this.model.data) {
            const { calledBy, department, location, category, subjectAreas, title, contactDetails } = this.model.data;
  
            this.isValid = calledBy && department && location && category && subjectAreas && title && contactDetails;
          }
        }
      }
    },
    methods: {
        init() {
          var vm = this;
          vm.loaded = false;

          vm.$AuthService.api.get(`supportticket/create` )
          .then(response => {
            vm.model = response.data;
            vm.createResult = null;
            vm.loaded = true;
          });
      },
      create() {
        var vm = this;
        vm.saving = true;

        vm.$AuthService.api.post(`/SupportTicket/Create`, vm.model.data)
          .then(response => {
            if (response.data.success) {
              vm.createResult = response.data;
              vm.saving = false;
              this.$emit('saved');
            }
        });
      },
      cancel() {
        this.dialog = false;
      },
      close() {
        this.dialog = false;
      }
    }
  }
</script>